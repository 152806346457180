body {
  font-family: "Titillium Web", sans-serif;
  padding: 0;
  margin: 0;
}
header {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(229, 229, 299);
  padding: 30px 0px 30px 40px;
  margin: 0;
}

div#logo {
  width: 100%;
  max-width: 1304px;
  height: 60px;
  background-image: url("/assets/barmer-logo-l.svg");
  background-position-x: initial;
  background-position-y: center;
  background-repeat: no-repeat;
}

main {
  max-width: 500px;
  padding: 16px;
  margin: auto;

  font-size: 18px;
  letter-spacing: -0.43px;
  line-height: 1.555556;
}
h1 {
  text-align: center;
  font-size: 32px;
  letter-spacing: -0.76px;
  line-height: 1.1875;
  color: rgb(74, 74, 74);
}
p {
  color: rgb(116, 116, 116);
}
div {
  text-align: center;
}
a {
  font-family: Arial, sans-serif;
  background-color: rgb(106, 165, 0);
  border-radius: 52px;
  color: rgb(255, 255, 255);
  height: 56px;
  padding: 8px 24px;
  box-sizing: border-box;
  min-width: 190px;
  text-decoration: none;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 1em;
}
a:hover {
  background-color: rgb(95, 148, 0);
  
}

@media screen and (max-width: 999px) {
  div#logo {
    height: 24px;
    background-image: url("/assets/barmer-logo-s.svg");
  }

  header {
    padding: 15px 0px 15px 20px;
  }


  h1  {
    font-size: 24px;
    letter-spacing: -0.57px;
    line-height: 1.25;
  }

  p  {
    text-align: center;
  }
}